import cupcake1 from "./cupcake-1.jpg";
import cupcake2 from "./cupcake-2.jpg";
import cupcake3 from "./cupcake-3.jpg";
import cupcake4 from "./cupcake-4.jpg";
import cupcake5 from "./cupcake-5.jpg";
import cupcake6 from "./cupcake-6.jpg";
import cupcake7 from "./cupcake-7.jpg";
import cupcake8 from "./cupcake-8.jpg";
import cupcake9 from "./cupcake-9.jpg";
import cupcake10 from "./cupcake-10.jpg";

export default [
  cupcake1,
  cupcake2,
  cupcake3,
  cupcake4,
  cupcake5,
  cupcake6,
  cupcake7,
  cupcake8,
  cupcake9,
  cupcake10,
];
