import other1 from "./other-1.jpg";
import other2 from "./other-2.jpg";
import other3 from "./other-3.jpg";
import other4 from "./other-4.jpg";
import other5 from "./other-5.jpg";
import other6 from "./other-6.jpg";
import other7 from "./other-7.jpg";
import other8 from "./other-8.jpg";

export default [other1, other2, other3, other4, other5, other6, other7, other8];
